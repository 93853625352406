<template>
    <div>
        <app-version-manager />
    </div>
</template>

<script>


import AppVersionManager from '@/components/DataManagement/AppVersion';
import {mapGetters} from "vuex";


export default {
    components : {
        AppVersionManager,
    },
    computed: {
        ...mapGetters({
            features: 'accountFeatures',
        }),
    },
    mounted() {

        setInterval(
            () => {
                if(this.features && this.features.selectionProcessingUI) {
                    this.$store.dispatch('processNextReload')
                }
            },
                15*1000
        );
    }
}
</script>
