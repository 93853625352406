<template>
    <div class="row ms-4 me-4 mt-4">
        <modal ref="sidebarMenuLayoutModal" size="xl">
            <sidebar-menu-layout>
                <template slot="sidebarToggle">Menu</template>
                <template #sidebar>
                    <searchable-groupable-list
                        :items="listBoxItemsFiltered"
                        :grouper="item => item.group"
                        :search-matches="search"
                        :search-terms="[
                            {display: '1', search: '1'},
                            {display: '2', search: '2'},
                            {display: '3', search: '3'}
                        ]"
                        class="mb-4"
                    >
                        <template #default="item">
                            <span>{{item.name}}</span>
                        </template>

                    </searchable-groupable-list>
                </template>
                <template #main class="d-flex flex-column">
                    <form class="full-width d-flex flex-column flex-grow">
                        <h4 class="form-header">
                            Hello
                            <div class="form-header-controls">
                                <toggle-switch></toggle-switch>&nbsp;
                                <button class="btn btn-primary btn-sm" type="button">World!</button>
                            </div>
                        </h4>
                        <div class="flex-grow">
                            Hello World!!!
                        </div>
                        <div class="form-footer-buttons form-footer d-flex">
                            <button type="submit" class="btn btn-primary me-2">Submit</button>
                            <button type="button" class="btn btn-secondary me-2" @click="() => $refs.sidebarMenuLayoutModal.show()">Other</button>
                            <button type="reset" class="btn btn-secondary me-2">Cancel</button>
                        </div>
                    </form>
                </template>
            </sidebar-menu-layout>
        </modal>
        <div class="col col-md-3 col-lg-2">
            <h6>Searchable Grouped List Box</h6>
            <searchable-groupable-list
                :items="listBoxItemsFiltered"
                :grouper="item => item.group"
                :search-matches="search"
                :search-terms="[
                    {display: '1', search: '1'},
                    {display: '2', search: '2'},
                    {display: '3', search: '3'}
                ]"
                class="mb-4"
            >
                <template v-slot:default="item">
                    <span>{{item.name}}</span>
                </template>

            </searchable-groupable-list>
            <h6>Simple List Box</h6>
            <searchable-groupable-list
                :items="listBoxItemsFiltered"
                :search-matches="search"
                :show-search="false"
                class="mb-4"
            >
                <template v-slot:default="item">
                    <span>{{item.name}}</span>
                </template>

            </searchable-groupable-list>
            <h6>Simple Search</h6>
            <easy-search
                :items="listBoxItems"
                @search="results => listBoxItemsFiltered = results"
                v-model="searchText"
                :matches="search"
                class="mb-2"
                placeholder="Search"
                clasess=""
            />
            <ul v-for="item in listBoxItemsFiltered">
                <li>{{item.name}}</li>
            </ul>
        </div>
        <form class="col col-md-6 col-lg-6 col-xl-5">
            <h4 class="form-header">
                Hello
                <div class="form-header-controls">
                    <toggle-switch></toggle-switch>&nbsp;
                    <button class="btn btn-primary btn-sm" type="button">World!</button>
                </div>
            </h4>
            <form-group-card title="Basic Inputs">
                <label class="label-calm form-label d-block mb-3">
                    Text
                    <input type="text" class="form-control mb-3">
                </label>
                <label class="label-calm form-label d-block mb-3">
                    Number
                    <input type="number" class="form-control">
                </label>
                <label class="label-calm form-label d-block mb-3">
                    Date
                    <input type="date" class="form-control">
                </label>
                <label class="label-calm form-label d-block mb-3">
                    Native Select
                    <select class="form-control">
                        <option>Test Option</option>
                    </select>
                </label>
                <label class="label-calm form-label d-block mb-3">
                    UpContent Select
                    <uc-select :items="[
                        {
                            description: 'Description',
                            subtitle: 'Subtitle',
                            path: [
                                { name: 'Account', icon: 'fa fa-users'},
                                { name: 'Folder', icon: 'fa fa-folder'}
                            ],
                            iconClass: 'fa fa-bolt'
                        }
                    ]">
                    </uc-select>
                </label>
                <label class="label-calm form-label d-block mb-0" for="ui-lib-multi-select">
                    UpContent Multi-Select
                </label>
<!--                Multi selects don't work with labels around them for some odd reason-->
                <uc-select id="ui-lib-multi-select" class="mb-3" multiple :items="[
                    {
                        description: 'Description',
                        subtitle: 'Subtitle',
                        path: [
                            { name: 'Account', icon: 'fa fa-users'},
                            { name: 'Folder', icon: 'fa fa-folder'}
                        ],
                        iconClass: 'fa fa-bolt'
                    }
                ]" :value="[]">
                </uc-select>

                <div>
                    <LabelerWithAddons>
                        <template v-slot:label>
                            Combo box
                        </template>
                        <template v-slot:label-right>
                            <tiny-toggle value="Include" :options="['Include', 'Exclude']"/>
                        </template>
                        <Combobox v-model="multiCombo" :multiple="true" :options="['test', 'A']">

                        </Combobox>
                    </LabelerWithAddons>
                </div>
                <fieldset class="mb-3">
                    <legend class="label-calm form-label d-block">Radios</legend>
                    <label class="form-check-label form-check">
                        <input type="radio" class="form-check-input">
                        Option 1
                    </label>
                    <label class="form-check-label form-check">
                        <input type="radio" class="form-check-input">
                        Option 2
                    </label>
                </fieldset>
                <fieldset class="mb-3">
                    <legend class="label-calm form-label d-block">Checkboxes</legend>
                    <label class="form-check-label form-check">
                        <input type="checkbox" class="form-check-input">
                        Option 1
                    </label>
                    <label class="form-check-label form-check">
                        <input type="checkbox" class="form-check-input">
                        Option 2
                    </label>
                </fieldset>
            </form-group-card>
            <form-group-card title="Advanced Inputs">
                <fieldset class="mb-3">
                    <legend class="label-calm form-label d-block">Toggle</legend>
                    <toggle small="true" value="collections" class="folder-nav--type-toggle full-width font-size-14">
                        <toggle-item value="collections" class="folder-nav--type-toggle-item ">
                            <collections-svg class="multiple light-variation icon-dynamic folder-nav--type-icon top-1"/>
                            Collections
                        </toggle-item>
                        <toggle-item value="topics" class="folder-nav--type-toggle-item">
                            <topics-svg class="multiple light-variation icon-dynamic folder-nav--type-icon top-1"/>
                            Topics
                        </toggle-item>
                    </toggle>
                </fieldset>
                <label class="label-calm d-block mb-3">
                    <collection-topic-selector>

                    </collection-topic-selector>
                </label>
                <label class="label-calm form-label d-block mb-3">
                    Icons
                    <icon-picker :icons="icons"/>
                </label>
                <label class="label-calm form-label d-block mb-3">
                    Color
                    <color-picker />
                </label>
                <scheduler>

                </scheduler>
                <p>Checkout EditableSelect! I'm far to lazy to try to implement one here but it's super useful!</p>
                <label class="label-calm d-block">Icon Checkbox</label>
                <icon-checkbox icon-class="fa-link"/>
                <label class="label-calm d-block">Toggle Switch</label>
                <toggle-switch></toggle-switch>
                <social-selector :value="[]" :multiple="true" :all-channels="[
                    { id: '1', profileName: 'Profile Name 1', networkName: 'Twitter', icon: 'fa fa-twitter'},
                    {id: '2', profileName: 'Profile Name 1', networkName: 'LinkedIn', icon: 'fa fa-linkedin'}
                ]"/>
                <highlight-checkbox :checked="[]" value="checked" color="primary">
                    Checkable Button
                </highlight-checkbox>
            </form-group-card>
            <div class="form-footer-buttons form-footer d-flex">
                <button type="submit" class="btn btn-primary me-2">Submit</button>
                <button type="button" class="btn btn-secondary me-2" @click="() => $refs.sidebarMenuLayoutModal.show()">Other</button>
                <button type="reset" class="btn btn-secondary me-2">Cancel</button>
            </div>
        </form>
        <div class="col col-md-4 col-lg-3 col-xl-2">
            <spinner></spinner>
            <progress-bar></progress-bar>
            <social-profile name="Profile Name" network="Twitter" icon="fa fa-twitter"/>
            <button type="button" class="btn btn-secondary"  @click="() => $refs.sidebarMenuLayoutModal.show()">
                Show Sidebar Menu Modal
            </button>
            <feature-list-category label="Category" class="mt-4">
                <feature-list-item :active="true">
                    <i class="fa fa-globe"/>
                    Web
                </feature-list-item>
                <feature-list-item :active="false">
                    <i class="fa fa-envelope"/>
                    Email
                </feature-list-item>
            </feature-list-category>
        </div>
    </div>
</template>

<script>
import UpcontentSelect from "../components/Controls/UpcontentSelect";
import GroupedListBox from "../components/Controls/GroupedListBox";
import Spinner from "../components/Controls/Spinner";
import ProgressBar from "../utilities/ProgressBar";
import ToggleItem from "../components/Controls/Toggle/ToggleItem";
import Toggle from "../components/Controls/Toggle/Toggle";
import CollectionsSvg from "../assets/svg/collections";
import TopicsSvg from "../assets/svg/topics";
import IconPicker from "../components/Controls/IconPicker";
import IconCheckbox from "../components/Controls/IconCheckbox";
import ColorPicker from "../components/Controls/ColorPicker";
import {iconOptions} from "@/services/Icons"
import FormGroupCard from "../components/Controls/FormGroupCard";
import ToggleSwitch from "../components/Controls/ToggleSwitch";
import SocialSelector from "../components/Controls/SocialSelector";
import SocialProfile from "../components/Controls/SocialProfile";
import HighlightCheckbox from "../components/Controls/HighlightCheckbox";
import CollectionTopicSelector from "../components/Controls/CollectionTopicSelector";
import EasySearch from "../components/Controls/EasySearch";
import EntityBadge from "../components/Controls/EntityBadge";
import SearchableGroupableList from "../components/Controls/SearchableGroupableList";
import Scheduler from "../components/Controls/SchedulerNew";
import Modal from "../components/Controls/Modal";
import SidebarMenuLayout from "../components/Layouts/SidebarMenuLayout";
import FeatureListCategory from "@/components/Controls/FeatureListCategory.vue";
import FeatureListItem from "@/components/Controls/FeatureListItem.vue";
import Combobox from "@/components/Controls/Combobox.vue";
import LabelerWithAddons from "@/components/Controls/LabelerWithAddons.vue";
import TinyToggle from "@/components/Controls/TinyToggle.vue";
import VueSelect from 'vue-select';

export default {
    name: "ComponentLibrary",
    components: {
        TinyToggle,
        VueSelect,
        LabelerWithAddons,
        FeatureListItem,
        FeatureListCategory,
        SidebarMenuLayout,
        Combobox,
        Scheduler,
        SearchableGroupableList,
        EntityBadge,
        EasySearch,
        CollectionTopicSelector,
        HighlightCheckbox,
        SocialProfile,
        SocialSelector,
        FormGroupCard,
        ColorPicker,
        IconCheckbox,
        IconPicker,
        TopicsSvg,
        CollectionsSvg,
        ToggleItem,
        ProgressBar,
        Spinner,
        GroupedListBox,
        Toggle,
        ToggleSwitch,
        Modal,
        UcSelect: UpcontentSelect
    },
    computed: {
        icons: () => iconOptions,
        listBoxItems() {
            return [
                {group: 'Group 1', name: 'Item 1'},
                {group: 'Group 1', name: 'Item 2'},
                {group: 'Group 2', name: 'Item 3'},
                {group: 'Group 2', name: 'Item 4'}
            ]
        }
    },
    data() {
        return {
            searchText: '',
            listBoxItemsFiltered: [
                {group: 'Group 1', name: 'Item 1'},
                {group: 'Group 1', name: 'Item 2'},
                {group: 'Group 2', name: 'Item 3'},
                {group: 'Group 2', name: 'Item 4'}
            ],
            multiCombo:[],
            multiSelect: []
        }
    },
    methods: {
        search(searchText, item) {
            return !searchText || item.name.includes(searchText);
        }
    }
}
</script>

<style scoped>

</style>
