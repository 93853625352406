<script setup>
import moment from "moment/moment";

console.log('report:click_history:setup:start');
import {computed, defineProps, ref} from 'vue';
import Spinner from "@/components/Controls/Spinner.vue";
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import StackedBarChart from "@/components/Controls/charts/StackedBarChart.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
    by: {type: String, default: 'week'},
});

const isShowNoDataMessage = ref(false);

const labels = computed(() => range(min_segment(), max_segment()).map(formatSegment));
const collectionNames = computed(() => Array.from(new Set(data.value.map(x => x.collection_name))))
const collections = computed(() => collectionNames.value.map((name) => {
    let rows = data.value.filter(x => x.collection_name === name);
    let collectionData = Array.from(labels.value.map(seg => rows.find(x => formatSegment(segment(x)) === seg)));
    return { name, data: collectionData.map(x => x && x.count || 0)};
}));
const datasets = computed(() => collections.value.map((collection) => (
    {data: collection.data, label: collection.name, stack: 'a'}
)));

const {data, load, listen, isLoading} = useReportData(() => `by_collection_by_${props.by}`, store, () => props.filters);

listen();
load().then(() => {
    console.log('report:click_history:data', collections.value)
    isShowNoDataMessage.value = data.value.length === 0;
});


const segment = x =>
    props.by === 'week' ? x.week_of_year :
    props.by === 'month' ? x.month :
    props.by === 'day' ? x.date :
    0;

const dataLen = () => data.value?.length
const min_segment = () => dataLen() ? data.value.map(x => segment(x)|| 1).sort()[0] : 0
const max_segment = () => dataLen() ? data.value.map(x => segment(x)|| 1).sort()[dataLen() - 1]: 0
const intRange = (start, stop) => new Array(stop - start).fill(start).map((x, y) => x + y);
function dateRange(startDate, endDate) {
    const range = [];
    let currentDate = moment(startDate);
    while (currentDate <= moment(endDate)) {
        range.push(currentDate.format('ddd') + ' ' + currentDate.format('l'));
        console.log('currentDate', currentDate)
        currentDate = currentDate.add(1, 'days');
    }
    console.log(range);
    return range;
}

const range = (min, max) => typeof min === 'number' ? intRange(min, max) : dateRange(min, max)

const formatSegment = value =>
    props.by === 'week' ? value :
    props.by === 'month' ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][value - 1] :
    props.by === 'day' ? value : value;

console.log('report:click_history:setup:finish', datasets.value, labels.value);
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <StackedBarChart
        v-else-if="!isLoading && !isShowNoDataMessage"
        id="my-chart-id"
        :labels="labels"
        :datasets="datasets"
    />
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
