<script setup lang="ts">
import {computed, defineProps, watch} from 'vue';
import {Pie} from "vue-chartjs";
import {ArcElement, Legend, PieController, Title, Tooltip} from "chart.js";
import {Chart} from "chart.js/auto";
Chart.register(ArcElement, PieController, Title, Tooltip, Legend)
import {chartColors} from "@/Constants";
const props = defineProps({
    datasets: {type: Array<any>},
    labels: {type: Array<any>},
    id: {type: String},
})
const colors = Object.values(chartColors)
const chartData = computed(() => ({
    labels: props.labels,
    datasets: props.datasets.map((dataset: any) => ({...dataset, backgroundColor: colors})),
}))
const chartOptions = {
    responsive: true,
    aspectRatio: 2,
    plugins: {
        legend: {
            position: 'right',
            maxWidth: 220,
            labels: {
                boxWidth: 30,
            }
        },
        datalabels: {
            display: false
        }
    }
}
watch(chartData, x => console.log('report:pie:data', x))
</script>

<template>
    <Pie
        :id="id"
        :options="chartOptions"
        :data="chartData"
    />
</template>

<style scoped>

</style>
