import * as types from "@/store/mutation-types";

// increment these variables to force logout users
const storeVersion = 9;
const logoutIfLoggedInBefore = 9;

const state = {
    appVersion : false,
    storeVersion: null,
}

const getters = {
    appVersion : state => state.appVersion,
    needsLogout: state => !Number.isInteger(state.storeVersion) || state.storeVersion < logoutIfLoggedInBefore,
}

const actions = {
    setAppVersion : ({commit}, {number, requiresReload}) => {
        commit("SET_CURRENT_APP_VERSION", {number, requiresReload})
    },
}

const mutations = {
    SET_CURRENT_APP_VERSION : (state, { number, requiresReload }) => {
        state.appVersion = {number, requiresReload}
    },
    [types.USER_DID_LOG_IN]: state => {
        state.storeVersion = storeVersion;
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}
