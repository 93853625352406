<script setup>
    import {defineProps, ref} from 'vue';
    import IconCheckbox from "@/components/Controls/IconCheckbox";
    const props = defineProps({
        span: {type: Number},
        label: {type: String},
        expand: {type: Boolean, default: false},
        vSpan: {type: Number, default: 1},
        downloadUrl: {type: String, default: ''},
        downloadName: {type: String, default: ''},
    })

    function downloadAFile(filename, text) {
        console.log('download', filename, text);
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    async function downloadCsv() {
        let resp = await window.$app.api.get(props.downloadUrl)
        console.log('text', resp.data)
        downloadAFile(props.downloadName, resp.data)
    }

    const printEnabled = ref(true)
</script>

<template>
    <div :class="`col col-12 col-lg-${props.span*6} col-xl-${props.span*3} mb-4 ${printEnabled ? '' : 'no-print'} dashboard-height-${props.vSpan}`">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <span>{{props.label}}</span>
                    <div class="d-flex">
                        <button class="btn btn-link font-size-18 mb-0 mt-0 p-0 me-2" type="button" @click="downloadCsv" v-if="props.downloadUrl">
                            <i class="fa fa-download font-size-18 mb-0 "></i>
                        </button>
                        <span class="font-size-18">
                            <icon-checkbox v-model="printEnabled" :icon-class="`fa ${printEnabled ? 'fa-eye' : 'fa-eye-slash'} font-size-18`" class="mb-0 print-checkbox"  title="Show on print/export"></icon-checkbox>
                        </span>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex justify-content-center" :class="props.expand ? 'dynamic-height' : 'static-height'">
                <slot/>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .card-body {
        min-height: 300px;
    }
    .dashboard-height-2 .card-body {
        min-height: 400px;
    }
    .dashboard-height-3 .card-body {
        min-height: 500px;
    }
    .dashboard-height-3 .card-body {
        min-height: 600px;
    }
    .card-body.dynamic-height {
        height: fit-content;
    }
    .card-body.static-height {
        height: 300px;
        max-height: 300px;
    }
    @media print {
        .no-print {
            display: none;
        }
        .print-checkbox {
            display: none;
        }
        .card {
            break-inside: avoid;
        }
    }
</style>
