import {Ref, ref, watch} from 'vue';
import {Store} from 'vuex'
import Api from "@/utilities/Api";
import {getUrl} from "dot-api/core/Urls";
import {getPath, getQueryString} from "@/composables/useReportData";

export function useReportDownloadUrls(store: Store<any>, filters: Ref<any>) {
    function downloadUrl(report_key) {
        const accountId = store.getters.currentAccountOverview.id;
        if (!filters.value || !accountId || !report_key) return '';
        return `${getPath(accountId, () => report_key)}.csv?${getQueryString(() => filters.value)}`;
    }
    return {
        downloadUrl
    }
}
