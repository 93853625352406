<template>
    <div class="d-flex justify-content-end justify-lg-content-between filters mb-3 btn-group">
        <div class="dropdown btn-group flex-grow" v-if="languageSupportsContentType">
            <a class="dropdown-toggle btn btn-sm btn-outline-primary flex-grow" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Type: <b>{{ filters.contentType }}</b>
            </a>
            <div class="dropdown-menu">
                <content-filter/>
            </div>
        </div>
        <div class="dropdown btn-group flex-grow">
            <a class="dropdown-toggle btn btn-sm btn-outline-primary flex-grow" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Sort: <b>{{ filters.sortBy }}</b>
            </a>
            <div class="dropdown-menu">
                <sort-filter/>
            </div>
        </div>
        <div class="dropdown btn-group flex-grow">
            <a class="dropdown-toggle btn btn-sm btn-outline-primary flex-grow" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Published: <b>{{ age }}</b>
                <span class="text-success" v-if="evergreen"><i class="ms-1 fa fa-tree"></i></span>
            </a>
            <div class="dropdown-menu">
                <age-filter/>
            </div>
        </div>
        <div class="dropdown btn-group flex-grow" v-if="!favorites">
            <a class="dropdown-toggle btn btn-sm btn-outline-primary flex-grow" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Sources: <span v-if="isLoadingSources">...</span><b v-else>{{ selectedSourceCount }}</b>
            </a>
            <div class="dropdown-menu dropdown-menu-end" style="min-width: 300px">
                <sources-filter/>
            </div>
        </div>
        <div v-if="features.tags" class=" dropdown btn-group flex-grow">
            <a class="dropdown-toggle btn btn-sm btn-outline-primary flex-grow" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Show:
                <i v-if="favorites" class="fa fa-star"></i>
                <i v-if="imageOnly" class="fa fa-image"></i>
                <i v-for="t in selectedTagObjects" :key="t.key" :class="`icon-dynamic ${t.iconClass}`"></i>
                <strong v-if="!favorites && !selectedTagObjects.length && !imageOnly">All</strong>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
                <tags/>
            </div>
        </div>
        <div v-else class="ms-sm-auto ms-md-2">
            <a href="#" @click.prevent="toggleFavorites">
                Showing:
                <b>
                    <span class="text-danger" v-if="favorites">
                        Favorites Only <i class="fa fa-star"></i>
                    </span>
                    <span class="text-muted" v-else>
                        All <i class="fa fa-star-o"></i>
                    </span>
                </b>

            </a>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SourcesFilter from './SuggestionFilters/Sources'
import GeographyFilter from './SuggestionFilters/Geography'
import AgeFilter from "./SuggestionFilters/Age"
import SortFilter from './SuggestionFilters/Sort'
import ContentFilter from './SuggestionFilters/Content'
import Tags from "@/components/FilteringAndSorting/SuggestionFilters/Tags";
import {languagesSupportingContentType} from "@/Constants";

export default {
    name: "HorizontalFilterDropdowns",
    components: {
        Tags,
        SourcesFilter, GeographyFilter, AgeFilter, SortFilter, ContentFilter,
    },
    data() {
        return {
            active: false
        }
    },
    computed: {
        ...mapGetters({
            filters: 'currentFilters',
            favorites: 'showFavoriteSuggestions',
            isLoadingSources: 'isLoadingSources',
            selectedSources: 'selectedSources',
            allTags: 'tags',
            features: 'features',
            hasSuggestions: 'hasSuggestions',
            currentTopic: 'currentTopic',
            selectedTags: 'selectedTags'
        }),
        languageSupportsContentType() {
            return !this.topicLanguage || languagesSupportingContentType.includes(this.topicLanguage)
        },
        topicLanguage() {
            if (this.hasSuggestions.type !== 'topic') return null;
            let culture = this.currentTopic?.culture;
            return Boolean(culture) && culture.split('-')[0]
        },
        age() {
            if (this.filters && this.filters.age == -1) {
                return 'Forever'
            }

            return this.filters.age + " " + this.$strings.plural('Day', this.filters.age);
        },
        evergreen() {
            return this.filters && this.filters.evergreen
        },
        selectedSourceCount() {
            return this.selectedSources ? this.selectedSources.length : 0
        },
        selectedTagObjects() {
            return this.selectedTags.map(tagKey => this.allTags.find(tag => tag && tag.key === tagKey)).filter(x => x)
        },
        imageOnly() {
            return this.filters.required && this.filters.required.includes('image')
        }
    },
    methods: {
        toggleFavorites() {
            this.$store.dispatch('toggleShowFavorites')
        }
    }
}
</script>

<style lang="scss" scoped>
/*@media screen and (min-width: 768px) {*/
/*    .filters-section {*/
/*        visibility: visible;*/
/*    }*/
/*}*/
.filters {
    position: relative;
    margin-left: auto;
    flex-wrap: wrap;
}

.filters a {
    font-size:var(--font-size-11)!important;
    text-transform: uppercase;
}

.filters > div {
    color: rgba(70, 70, 70, 0.8);
}

.dropdown-menu {
    padding: 10px;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .filters a {
        font-size:var(--font-size-11);
    }
    //.filters > div {
    //    margin: 0 .25em 0 0;
    //}
    .filters > div b {
        font-size:var(--font-size-11);
    }
}

@media screen and (max-width: 768px) {
    .filters.btn-group.d-flex {
        flex-direction: column;
        width: 100%;
    }
    .filters.btn-group {
        flex-direction: column;
    }
    .filters.btn-group .btn-group .btn {
        border-radius: 0 !important;
        margin-left: 0px !important;
        margin-top: -1px !important;
    }
    .filters.btn-group .btn-group:first-child  .btn{
        border-top-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
        margin-left: -1px !important;
    }
    .filters.btn-group .btn-group:last-child  .btn{
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
}

@media screen and (min-width: 768px) {
    .filters.btn-group.d-flex {
        flex-wrap: nowrap;
        flex-grow: 1;
    }
}

@media screen and (max-width: 850px) {
    .filters .btn {
        font-size: var(--font-size-10) !important;
    }
}

</style>

