import Vue from "vue";
import Vuex from "vuex";
import AccessControl from "@/utilities/ACL";
import Clipboard from "@/utilities/Clipboard";
import Loggy from "@/utilities/Loggy";
import Plan from "@/utilities/Plan";
import store from "@/store";
import Actions from "@/utilities/actions";
import Sort from "@/utilities/Sort";
import Strings from "@/utilities/Strings";
import Views from "@/utilities/Views";
import MobileCheck from "@/utilities/MobileCheck";
import AsyncComputed from "vue-async-computed";
import {ConfigCatPlugin} from "@/utilities/ConfigCat";
import StructuredApi from "@/utilities/StructuredApi";
import Shortener from "@/utilities/Shortener";
import {elementId, instanceId} from "@/utilities/Id";
import capitalize from "capitalize";
import moment from "moment";
import {appApi, limitedApi} from "@/init/ApiInit";
import {Apis} from "../api/index.ts";
import {axiosAppApi} from "./ApiInit";

// Use Declarations
// Vue.use(Vuex) // already called in store
Vue.use(AccessControl);
Vue.use(Clipboard)
Vue.use(Loggy);
Vue.use(Plan, {store});
Vue.use(Actions, {store})
Vue.use(Sort);
Vue.use(Strings);
Vue.use(MobileCheck);
Vue.use(AsyncComputed);
Vue.use(Views);
Vue.use(ConfigCatPlugin);



Vue.config.productionTip = false;
Vue.config.ignoredElements = ["zapier-zap-templates"];
Vue.prototype.$api = appApi
Vue.prototype.$api.limit = limitedApi;
let apis = new Apis(axiosAppApi);
Vue.prototype.$apis = new Apis(axiosAppApi);
Vue.use(StructuredApi)

let appShortener = new Shortener(process.env.VUE_APP_SHORTENER);
window.shortener = appShortener;
Vue.prototype.$shortener = appShortener;

Vue.prototype.$instanceId = instanceId;
Vue.prototype.$elementId = elementId;
Vue.prototype._ucCompId = '';

// Directives

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.event = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.event)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.event)
    },
});
Vue.config.errorHandler = (err) => {
    console.error(err);
};
// Deprecated for VUE 3
//
// Vue.filter('capitalize', (text) => {
//     if (text)
//         return capitalize(text);
// })
//
// Vue.filter('moment', (time) => {
//     if (time)
//         return moment.utc(time).fromNow()
// })
