<template>
    <div class="subscription-wrapper" v-show="!hideSubscription">
        <div class="subscription-display alert alert-blue p-2 ms-0 mb-1"  :class="{'hide' : !readyToDisplay}">
            <!-- HTML Binding Approved/Verified: Matthew Beatty, 2022-07-05, method: no user entered values used-->
            <span :class="{'show' : readyToDisplay}" v-html="text"></span>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    props: {
        debugMode: {type: Boolean, default: false},
    },

    data(){
        return {
            fadeIn : false,
            isSubscriptionToDisplay: false,
        }
    },
    watch: {
    },
    computed : {
        ...mapGetters({
            currentAccountOverview : 'currentAccountOverview',
            subscriptions : 'subscriptions',
            accountTeamMembers : 'accountTeamMembers',
            user : 'currentUser',
            isWhiteLabel: 'isWhiteLabel',
            subscriptionLimits: 'subscriptionLimits',
            hideSubscription: 'hideSubscription',
            appName: 'appName',
        }),
        plan(){
            return this.subscriptions.find(s => s.account_id === this.currentAccountOverview?.id);
        },
        canAdministerAccount(){
            return this.currentAccountOverview?.role === 'administrator';
        },
        text(){
            if ( ! this.plan){
               return '<a class="faux-router-link" href="#" data-to="/settings#subscription"><b>Upgrade</b></a> for more topics, distribution options, collaboration, and more. <a href="http://upcontent.com/pricing/" target="_blank"><b>Learn more.</b></a>'
            }
            let isTrialing = this.plan.status === 'trialing';
            // let $package = (this.plan && this.plan.package) || '';
            let planName = (this.plan && this.plan.plan_name) || '';
            let trialEnd = (this.plan && this.plan.trial_end);
            let galleries = (this.subscriptionLimits && this.subscriptionLimits.gallery_sites) || 0;
            let collections = (this.subscriptionLimits && this.subscriptionLimits.collections) || 0;
            // let hasValidBase = ['team', 'business', 'professional', 'custom', 'engage', 'orchest', 'share']
            //     .some(x => $package.includes(x))
            let daysLeft = this.daysUntil(trialEnd);
            let daysString = daysLeft
            if (daysLeft !== 1){
                daysString += ' days'
            } else {
                daysString += ' day'
            }

            if (daysLeft < 0 && ! isTrialing && !collections){
                return ''//'Your trial has ended. <a class="faux-router-link" href="#" data-to="/settings#subscription"><b>Upgrade</b></a> for more topics, distribution options, collaboration, and more. <a href="http://upcontent.com/pricing/" target="_blank"><b>Learn more.</b></a>'
            }

            if (isTrialing && daysLeft >= 0){
                return `You have <span class="text-danger">${daysString} left</span> in your trial of the ${planName} Plan.`
            }

            // if (isProfessional && ! isTrialing){
            //     return `Want to collaborate with your team on content? <a href="http://upcontent.com/pricing/" target="_blank">Learn more about the Teams Plan</a>.`;
            // }
            //
            if (galleries < 1 && !isTrialing){
                return `Want to add your favorite content to your website? <a href="https://upcontent.com/gallery/" target="_blank">Learn more about the ${this.appName} Gallery</a>.`
            }
            if(this.debugMode) {
                return 'Reminder: You can <a class="faux-router-link" href="#" data-to="/settings#subscription"><b>upgrade</b></a> for more topics, distribution options, collaboration, and more. <a href="https://upcontent.com/pricing/" target="_blank"><b>Learn more.</b></a>'
            }
            return null;
        },
        readyToDisplay(){
            return this.fadeIn && this.canAdministerAccount && !this.isWhiteLabel && this.text;
        },
    },
    mounted(){
        setTimeout(() => {this.fadeIn = true;}, 1500)
    },
    methods : {
        daysUntil(time){
            var nowUtc = moment.utc();
            var unixTime = moment.unix(time).utc().set({'hour' : 23, 'minute' : 59, 'second' : 59});
            return unixTime.diff(nowUtc, 'days');
        }
    }
}
</script>

<style scoped>
.subscription-display {
    width: 100%;
}
.subscription-display {
    font-size: var(--font-size-13);
    display: flex;
    align-items: center;
}

.subscription-display.hide {
    display: none;
}

.subscription-display span {
    opacity: 0;
    transition: opacity 750ms ease-in-out;
    color: white;
}

.subscription-display span.show{
    opacity: 1;
    color: inherit
}
</style>
