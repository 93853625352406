<script setup>
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import {defineProps, computed, ref} from 'vue'
import BigNumbers from "@/components/Controls/charts/BigNumbers.vue";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const isShowNoDataMessage = ref(false);

const count = computed(() => data.value.length);
const average = computed(() => sum(data.value.map(x => x.count)) / data.value.length);

const {data, load, listen, isLoading} = useReportData('top_articles', store, () => props.filters);

listen();
load().then(() => {
    console.log('report:top_articles:data', data.value.map(x => x.count || 0));
    isShowNoDataMessage.value = data.value.length === 0;
});

const sum = values => values.reduce((sum, item) => sum + item, 0);
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <big-numbers
        v-else-if="!isLoading && !isShowNoDataMessage"
        :values="[{unit: 'clicks/article', value: average}, {value: count, unit: 'articles'}]"
    ></big-numbers>
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
