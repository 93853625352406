<template>
    <power-up-container
        v-model="on"
        :service="service"
        :team="currentAccountOverview"
        :beforeAuth="beforeAuth"
        label="EveryoneSocial"
        description="Your employee advocacy program."
        :learn-more-url="learnUrls.everyoneSocial"
        iconClass="everyone_social-icon icon-dynamic"
        :visible="show && powerupSearchMatches('everyone social employee advocacy') && powerUpEnabled('everyoneSocial')">
        <spinner v-if="isLoading"></spinner>
        <everyone-social-collection-settings
            v-show="!isLoading && on"
            :saved-settings="savedSettings"
            :disclosures="disclosures"
            :social-groups="socialGroups"
            ref="settings">
        </everyone-social-collection-settings>
    </power-up-container>
</template>

<script>
import PowerUpContainer from "@/components/Controls/PowerUpContainer.vue";
import EveryoneSocialCollectionData from "@/components/Integrations/EveryoneSocial/EveryoneSocialCollectionData.vue";
import IntegrationSearch from "@/components/Integrations/IntegrationSearch.vue";
import EveryoneSocialCollectionSettings
    from "@/components/Integrations/EveryoneSocial/EveryoneSocialCollectionSettings.vue";
import {mapGetters} from "vuex";
import Spinner from "@/components/Controls/Spinner.vue";

export default {
    name: "EveryoneSocialAccordionSection",
    components: {Spinner, EveryoneSocialCollectionSettings, PowerUpContainer},
    mixins: [EveryoneSocialCollectionData, IntegrationSearch],
    data() {
        return {
            savedSettings: null,
        }
    },
    computed: {
        ...mapGetters({
            learnUrls: 'learnUrls',
            features: "accountFeatures",
        }),
        show() {
            return this.features.everyone_social
        }
    },
    methods: {
        save() {
            return this.updateSettingsForCollection({
                ...this.$refs.settings ? this.$refs.settings.constructSettingsApiObject() : {},
                on: this.on
            });
        },
        safeLoadEveryoneSocialIntegration() {
            if (this.features.everyone_social && this.collection && this.collection.id && this.isAuthorized) {
                this.loadForCollection();
            }
        }
    },
    watch: {
        savedSettings() {
            this.on = Boolean(this.savedSettings && this.savedSettings.on);
        }
    },
}
</script>

<style scoped>

</style>
