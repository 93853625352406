<script>
import {mapGetters} from 'vuex'
export default {
    props: ['value'],
    computed : {
        ...mapGetters({
            topics: 'topics',
            hasSuggestions: 'hasSuggestions',
            accountFeatures: 'accountFeatures',
        }),
        filters(){
            return this.value;
        },
        topicLanguage() {
            if (!this.hasSuggestions || this.hasSuggestions.type !== 'topic') return null;
            let culture = this.topics.find(x => x.id === this.hasSuggestions.id)?.culture;
            return Boolean(culture) && culture.split('-')[0]
        },
    },
    methods : {
        updateFilter(key, value){
            var filters = {...this.filters}
            filters[key] = value;
            this.$emit('input', filters);
        }
    }
}
</script>
