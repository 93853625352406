<template>

    <div class="topic-menu">
        <router-link class="dropdown-item" to="/" v-if="showBackToTopicLink">
            <div class="font-size-16"><i class="fa fa-arrow-left"></i> Back to Topic</div>
        </router-link>
        <a href="#scrolling-suggestions-container" v-else-if="topic" class="dropdown-item screen-reader-only">Focus
                                                                                                              Article
                                                                                                              List</a>

        <a href="#" v-if="topic" @click.prevent="showQueryBuilderForCurrentTopic" class="dropdown-item">
            <div class="font-size-14"><i class="fa fa-edit"></i> Edit <span v-text="$strings.truncate(topic.name, 30)"/></div>
            <small class="text-muted">Edit your query by adding, deleting, or changing your keywords</small>
        </a>

        <refresh-button v-if="topic && accountFeatures.topicRefresh"></refresh-button>

        <router-link v-if="topics.length" to="/topics" class="dropdown-item">
            <div class="font-size-14"><i class="fa fa-folder"></i> Manage my topics</div>
            <small class="text-muted">Edit and manage your topics and folders</small>
        </router-link>

        <a v-if=" ! isReviewer " href="#" @click.prevent="showQueryBuilder" class="dropdown-item">
            <div class="font-size-14"><i class="fa fa-plus"></i> Create New Topic</div>
        </a>


        <div v-if="topics.length">
            <div class="dropdown-divider"></div>

            <div class="dropdown-item" @click.prevent.stop>
                <div class="font-size-16">
                    <topics-svg class="multiple em-1"/>
                    Topics
                </div>
                <small class="text-muted">Select a topic to view suggestions</small>
            </div>

            <div class="topic-nav folders-container">
                <topics-by-folder @click.prevent.stop/>
            </div>
        </div>
    </div>
</template>

<script>

import TopicsByFolder from '@/components/Topics/TopicsByFolder'
import Roles from '@/utilities/Roles'
import TopicsSvg from "@/assets/svg/topics";
import {mapGetters} from 'vuex';
import RefreshButton from "@/components/Suggestions/RefreshButton";

export default {
    mixins: [Roles],
    props: [],
    components: {
        RefreshButton,
        TopicsByFolder,
        TopicsSvg,
    },
    computed: {
        ...mapGetters({
            topic: 'currentTopic',
            accountFeatures: 'accountFeatures',
            allTopics: 'topics',
            currentAccountOverview: 'currentAccountOverview',
        }),
        topics() {
            return this.allTopics.filter(t => t.account && t.account.includes(this.currentAccountOverview.id));
        },
        showBackToTopicLink() {
            return this.$route.name !== 'Suggestions'
        }
    },
    methods: {
        showQueryBuilder() {
            window.Events.$emit('showQueryBuilderModal', {topic: this.currentTopic});
        },
        showQueryBuilderForCurrentTopic() {
            window.Events.$emit('showQueryBuilderModal', {topic: this.topic, mode: 'advanced'});
        },
        switchToCollections() {
            this.$store.dispatch('setSuggestionViewerType', {type: 'collections'});
        }
    }
}
</script>

<style scoped>
.topic-nav.folders-container {
    padding: 0 1.55rem;
    font-size: 18px;
}
</style>
