<template>
    <v-select :options="options" v-model="selected" class="uc-select" label="description" ref="select" :class="{clearable, multiple}"
              :multiple="multiple"
              :searchable="searchable"
              :filterable="filterable"
              :disabled="disabled"
              :placeholder="placeholder"
              :clearable="clearable"
              :required="required"
    >
        <template v-slot:option="option">
            <div :class="layout">
                <span class="icon-container" v-if="option.iconClass">
                    <i :class="option.iconClass"></i>
                </span>
                <img class="option-image" alt="" v-if="!!option.iconUrl" :src="option.iconUrl" role="presentation">
                <div class="option-text">
                    <div style="overflow-wrap: break-word">{{ option.description }}</div>
                        <span v-if="option.subtitle && !useBigMode"
                              class="uc-select-option-subtitle text-muted">
                            ({{ option.subtitle }})
                        </span>
                        <div v-if="option.subtitle && useBigMode"
                             class="uc-select-option-subtitle text-muted small"
                             style="white-space: normal">
                            {{ option.subtitle }}
                        </div>
                    <div v-if="option.path && option.path.length" class="text-muted">
                        <span v-for="(folder, index) in option.path" :key="folder.name">
                            <span v-if="index > 0"> » </span>
                            <span v-if="folder.icon" :class="folder.icon" class="me-1"></span>
                        <span v-if="folder.name">{{ folder.name }}</span>
                    </span>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:selected-option="option">
            <span class="selected-option" @click="forceOpen" ref="selected">
                <span :class="layout">
                    <span class="icon-container" v-if="option.iconClass">
                        <i :class="option.iconClass"></i>
                    </span>
                    <img class="option-image" alt="" v-if="option.iconUrl" :src="option.iconUrl" role="presentation">
                    <span>{{ option.description }}</span>
                    <template v-if="!useShortMode">
                        <span v-if="option.subtitle && !useBigMode"
                              class="uc-select-option-subtitle text-muted">
                            ({{ option.subtitle }})
                        </span>
                        <div v-if="option.subtitle && useBigMode"
                             class="uc-select-option-subtitle text-muted small">
                            {{ option.subtitle }}
                        </div>
                    </template>
                    <span v-if="option.path && option.path.length"
                          class="uc-select-option-path text-muted font-size-13 ms-2">
                        <span v-for="(folder, index) in option.path" :key="folder.name">
                            <span v-if="index > 0"> » </span>
                            <span v-if="folder.icon"
                                  :class="folder.icon"
                                  class="uc-folder-icon me-1 font-size-12"></span>
                            <span v-if="folder.name">{{ folder.name }}</span>
                        </span>
                    </span>
                </span>
            </span>
        </template>
    </v-select>
</template>

<style scoped="">
.uc-select .icon-container {
    margin-right: .75em;
    width: 1em;
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.option-large .option-image {
    max-height: 48px;
    max-width: 48px;
    vertical-align: top;
}

.option-text {
    display: inline-block;
}

.selected-option .option-large .option-image,
.option-image {
    max-height: 24px;
    max-width: 24px;
    margin-right: .25em;
}

/*.selected-option {*/
/*display: inline-block;*/
/*}*/
</style>
<style lang="scss">
.uc-select button.vs__clear {
    display: none;
}
.uc-select.clearable.multiple button.vs__clear {
    display: block !important;
}

.input-group {
    .uc-select .dropdown-toggle,
    .uc-select .vs__dropdown-toggle {
        height: 100%;
    }

    .uc-select:not(:first-child) .dropdown-toggle,
    .uc-select:not(:first-child) .vs__dropdown-toggle {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .uc-select:not(:last-child) .dropdown-toggle,
    .uc-select:not(:last-child) .vs__dropdown-toggle {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.uc-select .vs__search::placeholder {
    color: var(--bs-clay-75);
    font-style: italic;
    opacity: 100%;
}

.fw .vs__selected {
    display: inline-block;
    width: calc(100% - 20px)
}

.vs__selected {
    color: var(--bs-dark);
}

/*.vs__clear {*/
/*display: none;*/
/*}*/

.selected-option > .main-page-filter-view {

}
</style>

<script>
import {VueSelect} from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    components: {vSelect: VueSelect},
    name: 'uc-select',
    props: {
        useBigMode: {type: Boolean, default: false}, // Special styles for subtitle text
        useShortMode: {type: Boolean, default: false}, // Only show subtitle inside menu, can be big and short!
        items: {type: Array, default: () => []}, // { value: String, style: String, label: String },
        value: {},
        layout: String,
        placeholder: String,
        filterable: {type: Boolean, default: false},
        searchable: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        multiple: {type: Boolean, default: false},
        clearable: {type: Boolean, default: false},
        required: Boolean
    },
    data() {
        return {
            selected: this.multiple ? this.getOptionsForValue() : this.getOptionForValue() || '',
            inValueChange: false
        }
    },
    watch: {
        selected() {
            // console.log('selected change', this.value, this.selected, this.options);
            if (!this.inValueChange && this.selectedHasChanged)
                this.$emit('input', this.multiple ? this.selectedValues : this.selectedValue)
        },
        value() {
            this.inValueChange = true;
            if (!this.multiple) {
                if (this.selectedHasChanged) {
                    this.selected = this.getOptionForValue() || ''
                }
            } else {
                console.log('value change', this.value, this.selectedValues, this.options)
                if (this.selectedHasChanged) {
                    this.selected = this.getOptionsForValue();
                }
            }
            this.inValueChange = false;
        },
        options() {
            if (!this.multiple) {
                if (this.value) {
                    this.selected = this.getOptionForValue()
                }
            } else {
                if (this.value) {
                    this.selected = this.getOptionsForValue();
                }
            }
        }
    },
    computed: {
        options() {
            return this.getOptions()
        },
        // useBigMode() {
        //     let textLengths = this.options && this.options.map(x => (x.description||'') + (x.subtitle||'') )|| [];
        //     let maxTextLength = Math.max(...textLengths);
        //     return maxTextLength >= 100;
        // },
        selectedValues() {
            return this.selected && this.selected.map(x => x.value)
        },
        selectedValue() {
            return this.selected && this.selected.value
        },
        selectedHasChanged() {
            if (this.multiple)
                return JSON.stringify(this.value) !== JSON.stringify(this.selectedValues) &&
                    JSON.stringify(this.value) !== JSON.stringify(this.selected);
            else
                return this.value != this.selected && this.value != this.selectedValue;

        },
        hasValue() {
            if (this.multiple)
                return Boolean(this.value && this.value.length)
            else {
                return Boolean(this.value);
            }
        },
        hasSelected() {
            if (this.multiple)
                return Boolean(this.selected && this.selected.length)
            else {
                return Boolean(this.selected);
            }
        },
        itemsMatchingValue() {
            return this.multiple ? this.getItemsForValue() : [this.getItemForValue()].filter(x => x)
        }
    },
    methods: {
        getOptions() {
            let needsPlaceholderWhileLoading = this.hasValue && !this.itemsMatchingValue.length;
            if (needsPlaceholderWhileLoading) {
                let values = this.multiple ? this.value : [this.value];
                let placeholders = values.map(x => ({value: x, description: 'Loading...'}));
                return [...this.items, ...placeholders]
            } else
                return this.items

        },
        getItemsForValue() {
            return this.items.filter(i => i && this.value.includes(i.value))
        },
        getItemForValue() {
            return this.items.find(i => i && i.value === this.value)
        },
        getOptionsForValue() {
            return this.getOptions().filter(i => i && this.value.includes(i.value))
        },
        getOptionForValue() {
            return this.getOptions().find(i => i && i.value === this.value)
        },
        forceOpen() {
            if (!this.disabled) {
                this.$refs.select.open = true;
                this.$refs.select.searchEl.focus();
            }
        },
        clearSelected() {
            this.selected = '';
        }
    }
}
</script>
