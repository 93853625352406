<script setup lang="ts">
    import {defineProps} from 'vue';
    defineProps({
        rows: {type: Array<any>},
        labels: {type: Array<any>},
    })
    function isComplex(cell) {
        console.log('reporting:table:cell', cell)
        return typeof cell === 'object'
    }
</script>

<template>
    <div class="full-width table-responsive">
        <table class="table" >
            <thead>
            <tr>
                <th v-for="label in labels" :key="label">{{label}}</th>
            </tr>
            </thead>
            <tbody class="">
            <tr v-for="row in rows">
                <td v-for="value in row">
                    <span v-if="isComplex(value)">
                        <a v-if="value.link" :href="value.link" target="_blank">{{value.label}}</a>
                        <span v-else>{{value.label}}</span>
                    </span>
                    <span v-else>{{value}}</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>

</style>
