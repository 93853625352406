import {ref, watch} from 'vue';
import {Store} from 'vuex'
import Api from "@/utilities/Api";
import {getUrl, queryString} from "dot-api/core/Urls";

export function getQueryString(filters: () => {
    collectionId;
    collectionMode;
    timespan;
    age;
    start;
    end;
    qpKey;
    qpValue;
    referrers;
    referrerMode;
    userAgents;
    userAgentMode
}) {
    let {
        collectionId,
        collectionMode,
        timespan,
        age,
        start,
        end,
        qpKey,
        qpValue,
        referrers,
        referrerMode,
        userAgents,
        userAgentMode
    } = filters();

    let params = {
        collections: collectionId || undefined,
        collection_mode: collectionMode.toLowerCase(),
        timespan,
        age: age || undefined,
        start: start || undefined,
        end: end || undefined,
        qp_key: qpKey || undefined,
        qp_value: qpValue || undefined,
        referrers: referrers || undefined,
        referrer_mode: referrerMode.toLowerCase(),
        // user_agents: userAgents || undefined,
        user_agent_mode: userAgentMode.toLowerCase(),
    }
    Object.keys(params).forEach(k => {
        if (!params[k]) delete params[k];
    }); // looks like this isn't working - still seeing empty params in the url
    let userAgentStr = '';
    for (const ua of userAgents) {
        userAgentStr += '&user_agents=' + encodeURIComponent(ua)
    }
    ;
    let qs = queryString(params) + userAgentStr;
    return qs;
}

export function getPath(accountId, getKey: () => string | (() => string)) {
    return `/accounts/${accountId}/reports/${getKey()}/data`;
}

export function useReportData(key: string|(() => string), store: Store<any>,  filters: () => {collectionId, collectionMode, timespan, age, start, end, qpKey, qpValue, referrers, referrerMode,  userAgents, userAgentMode}) {
    const data = ref([]);
    const isLoading = ref(false);
    const  fetchReportStatus = ref(null);
    const api: () => Api = () => window['$app'].api;

    const getKey = typeof key === 'string' ? () => key : key;
    async function fetch() {

        const accountId = store.getters.currentAccountOverview.id;
        if (!accountId || !filters()) return null;

        let url = `${getPath(accountId, getKey)}?${getQueryString(filters)}`;
        return await api().get(url)
    }
    async function load() {
        isLoading.value = true;
        const result = await fetch();
        console.log('report:result', result);
        data.value = result?.data || [];
        fetchReportStatus.value = result?.status;
        isLoading.value = false;
    }
    function listen() {
        watch(() => store.getters.currentAccountOverview, load)
        watch(() => filters(), load)
        watch(getKey, load)
    }
    return {
        data, fetch, load, listen, isLoading, fetchReportStatus
    }
}
