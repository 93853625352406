<script setup>
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import {defineProps, computed, ref, watch} from 'vue'
import BigNumber from "@/components/Controls/charts/BigNumber.vue";
import Spinner from "@/components/Controls/Spinner.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const isShowNoDataMessage = ref(false);

const numberValue = computed(() => data.value.map(x => x.count || 0)[0]);

const {data, load, listen, isLoading} = useReportData('account_summary', store, () => props.filters);

listen();
load().then(() => {
    console.log('report:account_summary:data', data.value.map(x => x.count || 0))
    isShowNoDataMessage.value = data.value.length === 0;
});

</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <big-number v-else-if="!isLoading && !isShowNoDataMessage" :value="numberValue" unit="Clicks" ></big-number>
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
