<script setup>
import {useReportData} from "@/composables/useReportData";
import store from '../../store';
import {ref, defineProps, computed, onMounted} from 'vue'
import DashboardTable from "@/components/Controls/charts/DashboardTable.vue";
import {StringUtils} from "@/utilities/Strings";
import {useShortener} from "@/composables/useShortener";
import Spinner from "@/components/Controls/Spinner.vue";
import CardChart from "@/components/Controls/charts/CardChart.vue";
import moment from "moment/moment";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const isShowNoDataMessage = ref(false);

const {data, load, listen, isLoading} = useReportData('top_articles', store, () => props.filters);
listen();
load().then(() => {
    console.log('report:top_articles:data', data.value.map(x => x.count || 0))
    isShowNoDataMessage.value = data.value.length === 0;
});
const {shortenById} = useShortener();

const rows = computed(() => data.value
    .map(x => [
        // {label: x.id, link: shorten(x)},
        StringUtils.truncate(x.domain, 30),
        {label: StringUtils.truncate(x.title, 75), link: shortenById(x.selection_id)},
        x.count
    ])
);
const cards = computed(() => data.value
    .map(x => ({
        text: StringUtils.truncate(x.domain, 30) || '',
        image: x.user_image || x.publisher_image,
        link: shortenById(x.selection_id),
        title: StringUtils.truncate(x.title, 60),
        subtitle: x.content_date && moment(x.content_date).format('l')|| '',
        badge: StringUtils.truncate(x.collection_name, 30),
        value: x.count,
        unit: 'Clicks'
    }))
);
const display = ref('table')
</script>

<template>
    <div class="full-width">
        <label class="label-calm full-width mb-2">
            <select class="full-width form-select form-select-sm" v-model="display">
                <option value="table">Display: Table</option>
                <option value="card">Display: Cards</option>
            </select>
        </label>
        <div v-if="isLoading" class="spinner-container">
            <Spinner :size="40"/>
        </div>
        <CardChart
            v-else-if="!isLoading && !isShowNoDataMessage && display === 'card'"
            :data="cards"
        ></CardChart>
        <dashboard-table
            v-else-if="!isLoading && !isShowNoDataMessage"
            :rows="rows"
            :labels="['Domain', 'Title', 'Count']"
        ></dashboard-table>
        <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
            <div class="alert alert-info">
                No data available with current filters and timespan
            </div>
        </div>
    </div>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
