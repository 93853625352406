<template>
    <a href="#" class="dropdown-item"  @click.prevent.stop="updateTopic" :disabled="alreadyRefreshing" :class="{disabled: alreadyRefreshing}">
        <div class="font-size-14"><i class="fa fa-refresh" :class="{'topic-refresh-rotate': alreadyRefreshing}"></i> Discover New Content</div>
        <small class="text-muted">Try to discover new content for this topic on the internet</small>
    </a>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "RefreshButton",
    data() {
        return {
            alreadyRefreshing: false
        }
    },
    computed: {
        ...mapGetters({
            topic: 'currentTopic'
        })
    },
    methods: {
        async updateTopic() {
            if (!this.alreadyRefreshing) {
                this.alreadyRefreshing = true;
                try {
                    await this.$api.put('/topics/'+this.topic.id+'/queries', {queries : this.topic.queries});
                    this.$store.dispatch('checkForNewSuggestions', {})
                } catch {
                    this.alreadyRefreshing = false
                }
            } else return true;
        }
    },
    mounted() {
        window.Events.$on('FinishedRefreshCurrentSuggestionUrls', () => {
            if (this.alreadyRefreshing) {
                Vue.nextTick(() => {
                    document.getElementById('open-suggestions-dropdown').click()
                })
            }
            this.alreadyRefreshing = false
        })
    }
}
</script>

<style scoped>
    @keyframes topic-refresh-rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    .topic-refresh-rotate {
        animation: topic-refresh-rotation 2s infinite linear;
    }
</style>
<style>
.dropdown-item.disabled, .dropdown-item:disabled {
    cursor: default;
}
.dropdown-menu.advanced-menu .dropdown-item.disabled h5 {
    color: var(--default) !important;
}
</style>
