<script setup>
import {defineProps, ref, watch} from 'vue';
import store from '../../store';
import PieChart from "@/components/Controls/charts/PieChart.vue";
import {usePieController} from "@/composables/usePieController";
import Spinner from "@/components/Controls/Spinner.vue";
import BigNumber from "@/components/Controls/charts/BigNumber.vue";

const props = defineProps({
    filters: {type: Object, default: () => {}},
});

const isShowNoDataMessage = ref(false);

const {labels, datasets, isLoading} = usePieController({
    key: 'by_collection',
    store,
    filters: () => props.filters,
    label: x => x.collection_name,
    value: x => x.count,
    minPercent: 0.05,
    maxCount: 10,
});

watch(()=> datasets.value, () => {
    isShowNoDataMessage.value = datasets.value[0].data.length === 0;
});
</script>

<template>
    <div v-if="isLoading" class="spinner-container">
        <Spinner :size="40"/>
    </div>
    <PieChart
        v-else-if="!isLoading && !isShowNoDataMessage"
        id="my-chart-id"
        :labels="labels"
        :datasets="datasets"
    />
    <div v-if="!isLoading && isShowNoDataMessage" class="spinner-container">
        <div class="alert alert-info">
            No data available with current filters and timespan
        </div>
    </div>
</template>

<style scoped>
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
