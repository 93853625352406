<template>
    <div class="folder-navigation-actions d-block small mb-3" v-show="role === 'manager'">
        <div>
            <button type="button" class="btn p-0 ui-link font-size-12 text-primary text-nowrap"
                    @click="create">
                <i class="fa fa-plus me-1"/>
                <span class="ui-link-text">New {{ createEntityName }}</span>
            </button>
        </div>
        <div>
            <router-link
                :to="managePath"
                class="btn p-0 ui-link font-size-12 text-primary text-nowrap text-start"
            >
                <i class="fa fa-folder me-1"/>
                <span class="ui-link-text">Manage All {{ createEntityName }}s</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "FolderNavigationActions",
    props: ['type'],
    computed: {
        ...mapGetters({
            role: 'currentRole',
            accountFeatures: 'accountFeatures',
            currentTopic: 'currentTopic',
        }),
        managePath() {
            return `/${this.type}`
        },
        createEntityName() {
            switch (this.type) {
                case "topics":
                    return " Topic";
                case "collections":
                    return " Collection";
                default:
                    return "";
            }
        },
    },
    methods: {
        create() {
            switch (this.type) {
                case "topics":
                    return this.showTopicBuilder();
                case "collections":
                    return this.showCollectionBuilder();
                default:
                    return;
            }
        },
        showCollectionBuilder(){
            if (this.accountFeatures.newCollectionUx) {
                window.Events.$emit('show_editCollectionModal', {collection: null});
            } else {
                window.Events.$emit('showCollectionCreationModal');
            }
        },
        showTopicBuilder() {
            window.Events.$emit('showQueryBuilderModal', {topic: false}); //this determines new topic being clear when user clicks "new topic"
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
