export function useShortener() {
    return {
        shorten(article: {id: string|null, source_url?: string|null}) {
            return window['shortener'].link(article)
        },
        shortenById(id: string) {
            return window['shortener'].link({id})
        }
    }
}
